var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"px-2"},[_c('v-subheader',{staticClass:"px-0"},[_c('b',[_vm._v("Convenios")]),(_vm.showFormActions)?[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){return _vm.agregarConvenio()}}},[_vm._v("AGREGAR CONVENIO")])]:_vm._e()],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"formProv_convenios",attrs:{"readonly":_vm.isFormReadonly}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersConvenios,"items":_vm.$store.state.entities.lstConvenios,"item-key":"index"},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"date","rules":[function (v) { return !!v || 'Dato Requerido'; }]},model:{value:(item.fecha),callback:function ($$v) {_vm.$set(item, "fecha", $$v)},expression:"item.fecha"}})]}},{key:"item.dias_credito",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","suffix":"días","rules":[function (v) { return v >= 0 || 'Es necesario que ponga un número entero positivo'; }]},model:{value:(item.dias_credito),callback:function ($$v) {_vm.$set(item, "dias_credito", $$v)},expression:"item.dias_credito"}})]}},{key:"item.email_soporte",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"rules":[function (v) { return _vm.validarCorreoElectronico(v); }]},model:{value:(item.email_soporte),callback:function ($$v) {_vm.$set(item, "email_soporte", $$v)},expression:"item.email_soporte"}}),(_vm.showFormActions)?_c('v-file-input',{staticClass:"customFile",attrs:{"accept":".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.gif,.jpg,.jpeg,.png","label":"Adjuntar Archivo","hide-input":""},on:{"change":function($event){return _vm.subirArchivoConvenio(item)}},model:{value:(_vm.fileinput),callback:function ($$v) {_vm.fileinput=$$v},expression:"fileinput"}}):_vm._e()],1)]}},(_vm.showFormActions)?{key:"item.action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.eliminarConvenio(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}:null],null,true)})],1)],1)],1),_c('br'),_c('v-subheader',{staticClass:"px-0"},[_c('b',[_vm._v("Tarifas")]),(_vm.showFormActions)?[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){return _vm.agregarTarifa()}}},[_vm._v("AGREGAR TARIFA")])]:_vm._e()],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"formProv_tarifas",attrs:{"readonly":_vm.isFormReadonly}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersTarifas,"items":_vm.$store.state.entities.lstTarifas,"item-key":"index"},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"date","rules":[function (v) { return !!v || 'Dato Requerido'; }]},model:{value:(item.fecha),callback:function ($$v) {_vm.$set(item, "fecha", $$v)},expression:"item.fecha"}})]}},{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Dato Requerido'; }]},model:{value:(item.codigo),callback:function ($$v) {_vm.$set(item, "codigo", $$v)},expression:"item.codigo"}})]}},{key:"item.email_soporte",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"rules":[function (v) { return _vm.validarCorreoElectronico(v); }]},model:{value:(item.email_soporte),callback:function ($$v) {_vm.$set(item, "email_soporte", $$v)},expression:"item.email_soporte"}}),(_vm.showFormActions)?_c('v-file-input',{staticClass:"customFile",attrs:{"accept":".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.gif,.jpg,.jpeg,.png","label":"Adjuntar Archivo","hide-input":""},on:{"change":function($event){return _vm.subirArchivoTarifa(item)}},model:{value:(_vm.fileinput),callback:function ($$v) {_vm.fileinput=$$v},expression:"fileinput"}}):_vm._e()],1)]}},{key:"item.tarifa",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.tarifa),callback:function ($$v) {_vm.$set(item, "tarifa", $$v)},expression:"item.tarifa"}})]}},(_vm.showFormActions)?{key:"item.action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.eliminarTarifa(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}:null],null,true)})],1)],1)],1)],1),(_vm.showNavigationButtons)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$store.state.entities.stepper--}}},[_vm._v("Regresar")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":_vm.validarFormulario}},[_vm._v("Continuar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }